import React from 'react';
import {useAuth} from '../../context/authContext';
import { Button, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import './selectNewAvatar.css';

export default function SubscriptionLevel() {

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

    const {
        defaultSubscription,
        setError
    } = useAuth();

    const showButton = () => {
        const cancelSubscription = async (userId, subscriptionId) => {
            try {
            const subscription = await stripePromise.subscriptions.retrieve(subscriptionId);
            await subscription.update(subscriptionId, {
                cancel_at_period_end: true,
            });
            // Handle the cancellation confirmation (e.g., update your database)
            // You may want to set a flag in your database to indicate that the user has canceled.
        
            // For example, if you're using Firebase, you can update the 'subscription' field in the user's document:
            // const docRef = doc(db, 'userSubscription', `${currentUser.uid}`);
            // await db.collection('users').doc(userId).update({
            //   subscription: 'canceled',
            // });
            } catch (err) {
            setError('Error canceling subscription: ' + err);
            }
        };
        if(defaultSubscription.name == "Free" || defaultSubscription.name == "Premium"){
            return (
                <Link to='/Subscriptions'>
                    <Button
                    aria-label='upgrade now'
                    >
                            Upgrade Now
                    </Button>
                </Link>
            )
        } if(defaultSubscription.name == "Premium"|| defaultSubscription.name == "Premium (Annual)"){
            return (
                <Link to='/Subscriptions'>
                    <Button 
                    aria-label='cancel subscription button'
                    class="btn btn-danger"
                    onClick={() => { cancelSubscription() }}
                    >
                        Cancel Subscription
                    </Button>
                </Link>
            )
        }
    }

  return (
    <div className='selectAvatarRightDiv'>
        <h3>Subscription Status</h3>
        <Card>
            <Card.Body>
                <div className='tiersSegments'>
                    <h5>{defaultSubscription.name}</h5>
                    <p>{defaultSubscription.description}</p>
                    {showButton()}
                </div>
            </Card.Body>
        </Card>
    </div>
  )
}
