import React, { useState, useRef } from 'react';
import { Link  } from 'react-router-dom';
import { Button, Card, Form, Alert, Container} from 'react-bootstrap';
import {useAuth} from '../context/authContext';

export default function ForgotPassword() {
    const emailRef = useRef();
    const {resetPassword} = useAuth();
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    
    async function handleSubmit(e) {
        e.preventDefault()
        try {
            setMessage('')
            setError('')
            setLoading(true)
            await resetPassword(emailRef.current.value)
            setMessage('Check your Inbox for further instructions')
        } catch {
            setError('Failed to reset password')
        }
        setLoading(false)
    }

    return (
        <div className="LoginWrap">
            <Container className="d-flex align-items-center justify-content-center"
            style={{minHeight: '100vh',
            zIndex: '1'
            }}>
            <div className="w-100" style={{maxWidth: '400px'}}>
                <Card>
                    <Card.Body>
                        <h2 className='text-center mb-4'>Password Reset</h2>
                        {message && (
                            <Alert variant='success'>
                                {message}
                            </Alert>
                        )}
                        {error && (
                            <Alert variant='danger'>
                                {error}
                            </Alert>
                        )}
                        <Form onSubmit={handleSubmit}>
                            <Form.Group id='email'>
                                <Form.Label>
                                    Email
                                </Form.Label>
                                <Form.Control 
                                type='email' 
                                ref={emailRef} 
                                required
                                />
                            </Form.Group>
                            <br/>
                            <Button 
                            aria-label='reset password button'
                            disabled={loading} 
                            className='w-100' 
                            type='Submit'
                            >
                                Reset Password
                            </Button>
                        </Form>
                        <div className="w-100 text-center mt-3">
                            <Link to='/SignIn'>
                                Log In
                            </Link>
                        </div>
                    </Card.Body>
                </Card>
                <div className="w-100 text-center mt-2">
                    Need an Account?
                    <br/>
                    <Link to='/SignUp'>
                        Sign Up
                    </Link>
                </div>
                </div>
            </Container>
        </div>
    )
}