import React, { useEffect, useState, useRef } from 'react';
import {Card} from 'react-bootstrap';
import { MdClose } from "react-icons/md";
import '../../components/User/lightbox.css';

export default function PersonalizedAds( {adsModalVisible: propAdsModalVisible, closeAdsModal: propCloseAdsModal} ) {
    let adsRef = useRef(null);
    const [localModalVisible, setLocalModalVisible] = useState(false);
    const toggleAdsModal = () => {
        setLocalModalVisible(!localModalVisible);
    };
    const closeAdsModal = () => {
        setLocalModalVisible(false);
        propCloseAdsModal();
    };
    
    const handleOutsideClick = (event) => {
        if (adsRef.current && !adsRef.current.contains(event.target) && propAdsModalVisible) {
            closeAdsModal();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [propAdsModalVisible]);
    return (
        <>
        {propAdsModalVisible && (
        <div className="scrollArea">
            <div 
            className="legalLightBoxWrap" 
            ref={adsRef}
            >
                <button 
                aria-label='close personalized ads modal'
                onClick={closeAdsModal} 
                className='lightboxCloseButton'
                >
                    <MdClose style={{ width: "40px", height: "40px" }} />
                </button>
                <Card className='cardInnerDiv'>
                    <Card.Body>
                        <div>
                            <h3>Personalized Ads Disclosure</h3>
                            <p>Last Updated: 10/31/2023</p>
                            <br/>
                            <h5>Introduction</h5>
                            <p>
                            At Resumancy, we care about your privacy and are committed to providing a transparent and secure online experience. This "Personalized Ads Disclosure" explains how we use advertising services provided by Primis Ads and Google AdSense to display personalized ads on our website.
                            </p>
                            <br/>
                            <h5>What are Personalized Ads?</h5>
                            <p>Personalized ads, also known as interest-based ads, are advertisements tailored to your interests based on your online activity and browsing behavior. These ads are designed to provide a more relevant and engaging advertising experience.</p>
                            <br/>
                            <h5>Our Advertising Partners</h5>
                            <p>We work with two primary advertising partners to serve personalized ads on our website:</p>
                            <ul>
                                <li>
                                    <p>Primis Ads: Primis Ads is an advertising platform that helps us display video ads to our visitors. Primis Ads may use cookies and other tracking technologies to deliver personalized video ads based on your online behavior.</p>
                                </li>
                                <li>
                                    <p>Google AdSense: Google AdSense is an advertising program provided by Google. It allows us to display text, image, and video ads on our website. Google AdSense uses cookies and other technologies to deliver ads tailored to your interests.</p>
                                </li>
                            </ul>
                            <br/>
                            <h5>What Information is Collected?</h5>
                            <h6>Primis Ads and Google AdSense may collect certain information to personalize the ads you see. This information may include, but is not limited to:</h6>
                            <ul>
                                <li>Your IP address</li>
                                <li>Cookies and similar tracking technologies</li>
                                <li>Browsing history</li>
                                <li>Location data</li>
                                <li>Device information</li>
                            </ul>
                            <br/>
                            <h5>
                                Your Choices
                            </h5>
                            <p>You have the option to manage your ad preferences and opt-out of personalized ads at any time. To do so, please refer to the privacy settings provided by our advertising partners or use industry-standard opt-out tools. Please note that opting out of personalized ads does not mean you will no longer see ads; it simply means the ads you see may be less relevant to your interests.</p>
                            <br/>
                            <h5>Your Privacy Rights</h5>
                            <p>We respect your privacy rights, and our use of personalized ads is subject to our Privacy Policy, which outlines how we collect, use, and protect your personal information. For more information on your privacy rights and how to exercise them, please refer to our Privacy Policy.</p>
                            <br/>
                            <h5>Updates to this Disclosure</h5>
                            <p>We may update this "Personalized Ads Disclosure" from time to time to reflect changes in our advertising practices or applicable regulations. When we make updates, we will revise the "Last Updated" date at the beginning of this disclosure.</p>
                            <br/>
                            <h5>Contact Us</h5>
                            <p>If you have any questions, concerns, or requests regarding this "Personalized Ads Disclosure," please don't hesitate to <a href='mailto:Support@Resumancy.com'>Contact Us</a>.
                            <br/>
                            <br/>
                            Thank you for visiting Resumancy and for trusting us with your privacy. We appreciate your continued support.</p>
                        </div>
                    </Card.Body>
                </Card>
                
            </div>
        </div>
        )}</>
    )
}
