import React, {useState} from 'react';
import { Button, Card, Alert} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css/bundle";
import { Pagination, Autoplay } from "swiper/modules";

import './main.css';
import {useAuth} from '../context/authContext';

import Header from '../components/header';
import Footer from '../components/footer';
import Faq from './legalPages/faq';
import defaultResumeStyle from '../static/resumeStyleImgs/Jimmy_Bob_scratchResume.jpg';
import resumeStyle2 from '../static/resumeStyleImgs/Jimmy_Bob_scratchResume (1).jpg';
import resumeStyle3 from '../static/resumeStyleImgs/Jimmy_Bob_scratchResume (2).jpg';
import resumeStyle4 from '../static/resumeStyleImgs/Jimmy_Bob_scratchResume (3).jpg';
import resumeStyle5 from '../static/resumeStyleImgs/Jimmy_Bob_scratchResume (4).jpg';


export default function Home() {
    const {
        defaultSubscription,
        error
    } = useAuth();
    const [faqModalVisible, setFaqModalVisible] = useState(false);
    
    const closeFaqModal = () => {
        setFaqModalVisible(false);
    };
    const toggleFaqModal = () => {
        setFaqModalVisible(!faqModalVisible);
    };
    
    const currentSubscriptionSection = () => {
        if(defaultSubscription.name === "Free") {
            return (
                <p>
                    You may create an unlimited number of Resumes with our Free Tier.
                </p>
            )
        }
        if(defaultSubscription.name === "Premium" || defaultSubscription.name === "Premium (Annual"){
            return (
                <p>
                    Most Premium!
                </p>
            )
        }
    }
    const showSection = () => {
        if(defaultSubscription.name === "Free") {
            return (
                <div>
                    <h3>Upgrade Today</h3>
                    <p className='leftShiftedText'>
                    Upgrading your subscription can give you access to some of our most popular premium services such as our Cover Letter Generator.
                    With Premium you also have access to all your Previous Resume files.
                    </p>
                </div>
                )
        }
        
    }
    const showButton = () => {
        if(defaultSubscription.name === "Free"){
            return (
                <Link to='/Subscriptions'>
                    <Button
                    aria-label='upgrade now'
                    >
                        Upgrade Now
                    </Button>
                </Link>
            )
        } 
    }

    return (
        <>
        {error && (
            <Alert variant='danger' dismissible className='alerts'>
                <Alert.Heading>Error</Alert.Heading>
                <p>{error}</p>
            </Alert>
        )}
        <Header />
        {faqModalVisible && <Faq faqModalVisible={faqModalVisible} closeFaqModal={closeFaqModal} /> }
        <div className='pageWrapper'>
            <div className='secondaryPageWrapper'>
                <div className='leftDiv'>
                    <Card className='homeLeftCard'>
                        <Card.Body>
                            <div className='cardInnerDiv'>
                                <h4>Sample Resumes</h4>
                                <div className='homeLeftCardImageDiv'>
                                <Swiper
                                    pagination={{
                                    dynamicBullets: true,
                                    }}
                                    modules={[Pagination, Autoplay]}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    className="sampleResumeSwiper"
                                >
                                    <SwiperSlide>
                                        <div className='swiperSlideDiv'>
                                            <h4>Default Style</h4>
                                            <img src={defaultResumeStyle} alt='Default Resume Style'/>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='swiperSlideDiv'>
                                            <h4>Sleek Sophistication Style</h4>
                                            <img src={resumeStyle2} alt='Sleek Sophistication Style'/>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='swiperSlideDiv'>
                                            <h4>Professional Prodigy</h4>
                                            <img src={resumeStyle3} alt='Sleek Sophistication Style'/>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='swiperSlideDiv'>
                                            <h4>Elegant Edge</h4>
                                            <img src={resumeStyle4} alt='Sleek Sophistication Style'/>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='swiperSlideDiv'>
                                            <h4>Executive Excellence</h4>
                                            <img src={resumeStyle5} alt='Sleek Sophistication Style'/>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <div className='rightDiv'>
                    <Card>
                        <Card.Body>
                            <div className='cardInnerDiv'>
                                <div>
                                    <h4>What We Do</h4>
                                    <p className='leftShiftedText'>
                                    We use AI to transform your job search. 
                                    <br/> 
                                    Start with our Base Resume, then tailor it for specific jobs by providing a title and description. No more generic applications – stand out with personalized resumes showcasing your strengths. Elevate your job search with Resumancy and let AI be your secret weapon for landing your dream job. <br/> To learn more visit our 
                                        <span onClick={toggleFaqModal} className='faqSpan'>
                                        <Link to=''>
                                            FAQ
                                        </Link>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <div className='cardInnerDiv'>
                                <h4>Get Going</h4>
                                <div className='alignLeft'>
                                    <p>
                                        <span className='HomeSectionTitle'>Part 1</span>
                                        <br/>
                                        <span className='HomeSectionSubScript'>Create a Base Resume.</span>
                                        <br/>
                                        <span className='homeNum'>Step 1. </span> Create a Base Resume by clicking <b>Create</b>. 
                                        <br/>
                                        <span className='homeNum'>Step 2. </span> Verify everything looks good (make edits if needed).
                                        <br/>
                                        <br/>
                                        <span className='HomeSectionTitle'>Part 2</span>
                                        <br/>
                                        <span className='HomeSectionSubScript'>Create Custom Resumes for each employer.</span>
                                        <br/>
                                        <span className='homeNum'>Step 3. </span> Click on <b>Create</b> again, provide prospective job description(s).
                                        <br/>
                                        <span className='homeNum'> Step 4. </span> Enjoy your custom tailored resume(s).
                                    </p>
                                </div>
                                <Link to='/Create'>
                                    <Button
                                    aria-label='create button'
                                    >
                                        Create
                                    </Button>
                                </Link>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <div className='cardInnerDiv'>
                                <div className='homeCardInnerDiv'>
                                    <h4>Current Tier: {defaultSubscription.name}</h4>
                                    {currentSubscriptionSection()}
                                </div>
                                <br/>
                                <div className='homeCardInnerDiv'>
                                    {showSection()}
                                    {showButton()}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}