import React, { useEffect, useState, useRef } from 'react';
import {Card} from 'react-bootstrap';

import { MdClose } from "react-icons/md";
import '../../components/User/lightbox.css';

export default function ContactUs( {contactModalVisible: propContactModalVisible, closeContactModal: propCloseFaqModal} ) {
    let contactRef = useRef(null);
    const [localModalVisible, setLocalModalVisible] = useState(false);
    const toggleContactModal = () => {
        setLocalModalVisible(!localModalVisible);
    };
    const closeContactModal = () => {
        setLocalModalVisible(false);
        propCloseFaqModal();
    };
    
    const handleOutsideClick = (event) => {
        if (contactRef.current && !contactRef.current.contains(event.target) && propContactModalVisible) {
            closeContactModal();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [propContactModalVisible]);
    return (
        <>
        {propContactModalVisible && (
        <div className="scrollArea">
            <div 
            className="legalLightBoxWrap" 
            ref={contactRef}
            >
                <button 
                aria-label='close modal button'
                onClick={closeContactModal} 
                className='lightboxCloseButton'
                >
                    <MdClose style={{ width: "40px", height: "40px" }} />
                </button>
                <Card className='cardInnerDiv'>
                    <Card.Body>
                        <div>
                            <h3>Contact Us</h3>
                            <h4><a href="mailto:Support@Resumancy.com">Support@Resumancy.com</a></h4>
                            <p>feel free to reach out</p>
                        </div>
                    </Card.Body>
                </Card>
                
            </div>
        </div>
        )}</>
    )
}
