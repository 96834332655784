import React from 'react';
import { Form } from 'react-bootstrap'
import {useAuth} from '../../context/authContext';
import DOMPurify from 'dompurify';

import '../../views/main.css';

export default function EditResume() {
    const {
        resumeData,
        setResumeData,
    } = useAuth();

    const handleChange = (field, value) => {
        const sanitizedValue = DOMPurify.sanitize(value);
        setResumeData({
            ...resumeData,
            [field]: sanitizedValue,
        });
    };

    const capitalizeFirstLetter = (text) => {
        return text.charAt(0).toUpperCase() + text.slice(1);
    };

    return (
        <>
        <div className='cardInnerDiv'>
            <Form.Group>
                <Form.Label>
                    First Name
                </Form.Label>
                <Form.Control 
                type='text' 
                placeholder='first name'
                value={capitalizeFirstLetter(resumeData.firstName)}
                onChange={(e) => handleChange('firstName', e.target.value)}
                maxLength='50'
                />
                <Form.Label>
                    Last Name
                </Form.Label>
                <Form.Control 
                type='text' 
                placeholder='Last name'
                value={capitalizeFirstLetter(resumeData.lastName)}
                onChange={(e) => handleChange('lastName', e.target.value)}
                maxLength='50'
                />
                <Form.Label>
                    Job Title
                </Form.Label>
                <Form.Control 
                type='text' 
                placeholder='Job Title'
                value={resumeData.jobTitle}
                onChange={(e) => handleChange('jobTitle', e.target.value)}
                maxLength='50'
                />
                <Form.Label>
                    Contact Info
                </Form.Label>
                <Form.Control 
                as='textarea'
                rows={3}
                placeholder='Contact Info'
                value={resumeData.contactInfo}
                onChange={(e) => handleChange('contactInfo', e.target.value)}
                maxLength='500'
                />
                <Form.Label>
                    Summary
                </Form.Label>
                <Form.Control 
                as='textarea'
                rows={3}
                placeholder='Summary'
                value={resumeData.summary}
                onChange={(e) => handleChange('summary', e.target.value)}
                maxLength='1000'
                />
                <Form.Label>
                    Experience
                </Form.Label>
                <Form.Control 
                as='textarea'
                rows={5}
                placeholder='Experience'
                value={resumeData.experience}
                onChange={(e) => handleChange('experience', e.target.value)}
                maxLength='2000'
                />
                <Form.Label>
                    Education
                </Form.Label>
                <Form.Control 
                as='textarea'
                rows={3}
                placeholder='Education'
                value={resumeData.education}
                onChange={(e) => handleChange('education', e.target.value)}
                maxLength='500'
                />
                <Form.Label>
                    Skills
                </Form.Label>
                <Form.Control 
                as='textarea'
                rows={5}
                placeholder='Skills'
                value={resumeData.skills}
                onChange={(e) => handleChange('skills', e.target.value)}
                maxLength='500'
                />
            </Form.Group>
        </div>
        </>
    )
}
