import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import "../header.css";

import logo from '../../static/logo_monochrome.png';


export default function LoginHeader() {
    return (
        <div className="Login_head_wrap">
            <div className="loginHeaderMid">
                <div>
                    <Link to='/'>
                        <img src={logo} alt="Logo" id="loginLogoSmall2"/>
                    </Link>
                </div>
                <div>
                    <h1>
                        Resumancy
                    </h1>
                    <h4 id="beta_tag">
                        AI Resume Builder
                    </h4>
                </div>
            </div>
        </div>
    )
}
