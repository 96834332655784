import React, { useState, useEffect } from "react";
import { useAdsLogicContext } from "../../context/adsLogicContext";
import { useAuth } from "../../context/authContext";
import { Button, Card, Alert, OverlayTrigger, Tooltip} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { BsQuestionCircle } from "react-icons/bs";
import "../../views/main.css";
import DOMPurify from 'dompurify';
import LoadingPage from "../loadingPage";

export default function NewResumePage() {
  const { isModalVisible, toggleModal, timer } = useAdsLogicContext();
  const {
    defaultSubscription,
    resumeData,
    handleAddFromJobDescriptionResume,
    handleAddFromJobDescriptionPremiumResume,
    companyName,
    setCompanyName,
    fromJobDesc,
    setFromJobDesc,
    setCoverLetterContent,
    error,
    resumeContent,
    setResumeContent,
    setError,
    setLoading,
    userFailsafe, 
    setUserFailsafe,
    loading
  } = useAuth();
  const history = useNavigate();
  const [userInput1, setUserInput1] = useState("");
  const [showAlert, setShowAlert] = useState(true);
  
  const sanitizeUserInput = (input) => {
    return DOMPurify.sanitize(input);
  };
  const handleInputChange1 = (event) => {
    setUserInput1(sanitizeUserInput(event.target.value));
  };
  const handleCompanyNameChange = (event) => {
    setCompanyName(sanitizeUserInput(event.target.value));
  };
  const closeModal = () => {
    toggleModal();
  };

  useEffect(()=>{
    if(!userFailsafe){
      setLoading(false);
      history("/ReviewResumePAge")
    }
  }, [loading, userFailsafe])
  const closeModalAndNavigate = async () => {
    setLoading(true);
    closeModal();
  };


  const handleSubmit = () => {
    if(
      userInput1.trim()==='' ||
      companyName.trim()===''
    ){
      setError('All Fields Are Required');
      return;
    }
    if (defaultSubscription.name === "Free") {
      toggleModal();
      generateResume(userInput1, resumeData);
    } else if (
      defaultSubscription.name === "Premium" || defaultSubscription.name === "Premium (Annual)"
    ) {
      setLoading(true);
      generateCoverLetter(userInput1, resumeData);
      generatePremiumResume(userInput1, resumeData);
    } else {
      setLoading(true);
      generatePremiumResume(userInput1, resumeData);
    }
  };

  const generatePremiumResume = async (userInput1, resumeData) => {
    try {
      const response = await fetch(
        "https://us-central1-gpt-resume-builder.cloudfunctions.net/from_job_des_functions-from_job_des_functions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userInput1: userInput1,
            resumeData: resumeData,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to generate resume");
      }
      const data = await response.json();
      const resumeSummary = data.resumeSummary;
      setResumeContent(resumeSummary);
      setFromJobDesc(resumeSummary);
      setLoading(false)
      history('/ReviewResumePage');
    } catch (error) {
      setError("Error generating resume:" + error);
    }
  };
  const generateResume = async (userInput1, resumeData) => {
    try {
      const response = await fetch(
        "https://us-central1-gpt-resume-builder.cloudfunctions.net/from_job_des_functions-from_job_des_functions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userInput1: userInput1,
            resumeData: resumeData,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to generate resume");
      }
      const data = await response.json();
      const resumeSummary = data.resumeSummary;
      await setResumeContent(resumeSummary);
      await setFromJobDesc(resumeSummary);
      setLoading(false);
      setUserFailsafe(false);
    } catch (error) {
      setError("Error generating resume:" + error);
    }
  };
  const generateCoverLetter = async (userInput1, resumeData) => {
    try {
      const response = await fetch(
        "https://us-central1-gpt-resume-builder.cloudfunctions.net/cover_letter_functions-cover_letter_functions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userInput1: userInput1,
            resumeData: resumeData,
          }),
        }
      );
      if (!response.ok) {
        setError("Failed to generate resume");
      }
      const data = await response.json();
      const coverLetterSummary = data.coverLetterSummary;
      setCoverLetterContent(coverLetterSummary);
    } catch (error) {
      setError("Error generating cover letter:" + error);
    }
  };
  const companyNameTooltip = "The name of the company you are applying for.";
  const prospectiveJobDescTooltip = "Copy/Paste a description of the role you are applying for.";
  useEffect(() => {
      if (
        defaultSubscription.name === "Premium" || defaultSubscription.name === "Premium (Annual)"
      ) {
        handleAddFromJobDescriptionPremiumResume(companyName, resumeData);
      } else {
        handleAddFromJobDescriptionResume(companyName, resumeData);
      }
  }, [fromJobDesc]);

  useEffect(() => {
    const alertTimeout = setTimeout(() => {
        setShowAlert(false);
    }, 10000);
    return () => clearTimeout(alertTimeout);
}, []);

  return (
    <>
    {error && !loading && (
      <Alert variant="danger" className='alerts' dismissible>
        <Alert.Heading>Error</Alert.Heading>
        <p>{error}</p>
      </Alert>
    )}
      {isModalVisible && 
        <div className="showAdsModal">
          <div className="showAdsWrap">
            <div className="adsVideoPlayer">
              <h3>Brewing up a Resume</h3>
            </div>
            <div className="showAdsNav">
              <div>
                <p>want to get more out of your resume?</p>
                <Link to="/Subscriptions">
                  <Button
                  aria-label='upgrade subscription button'
                  >
                    Upgrade
                  </Button>
                </Link>
              </div>
              <div>
                <div className="timer">{timer} seconds remaining</div>
                <Button
                  aria-label='close ad modal button'
                  onClick= {closeModalAndNavigate}
                  disabled={timer > 0}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </div>
      }
      {showAlert && 
        <Alert variant='warning'className='alerts' dismissible>
          <Alert.Heading>
            Alert
          </Alert.Heading>
          <p>This Component will not function properly with adBlocker, please disable your adBlocker for this site</p>
        </Alert>
      }

      <div className="pageWrapper">
        <h3>Step 2</h3>
        <br />
        <div className="secondaryPageWrapper">
          <div className="cardInnerDiv" id="jobDescriptionCardInnerDiv">
            <Card>
              <Card.Body className="cardBody">
                <h4>
                  Prospective Company Name<span className="required-indicator">*</span> {" "} 
                <OverlayTrigger 
                  overlay={
                  <Tooltip id="tooltip">
                    {companyNameTooltip}
                  </Tooltip>
                }>
                  <span className="question-icon">
                    <BsQuestionCircle />
                  </span>
                </OverlayTrigger>
                </h4>
                <textarea
                  rows="1"
                  cols="75"
                  value={companyName}
                  onChange={handleCompanyNameChange}
                  placeholder="Enter the prospective company name here..."
                  className="mobile-textarea"
                />
                <br />
                <h4>Prospective Job Description<span className="required-indicator">*</span> {" "} 
                  <OverlayTrigger 
                    overlay={
                    <Tooltip id="tooltip">
                      {prospectiveJobDescTooltip}
                    </Tooltip>
                  }>
                    <span className="question-icon">
                      <BsQuestionCircle />
                    </span>
                  </OverlayTrigger>
                </h4>
                <textarea
                  rows="10"
                  cols="75"
                  value={userInput1}
                  onChange={handleInputChange1}
                  placeholder="Enter the prospective job description here..."
                  className="mobile-textarea"
                />
                <br />
                <p  className="required-note">Fields marked with <span className="required-indicator">*</span> are required.</p>
              </Card.Body>
            </Card>
            <Button
              aria-label='submit button'
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </Button>
          </div>
        </div>
        <div>
          Edit your scratch Resume
          <br />
          <Link to="/ReviewResumePage">
            <Button
            aria-label='edit button'
            >
              Edit
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
}
