import React, { useEffect, useState } from 'react'
import {db} from '../../server/firebaseConfig';
import {useAdsLogicContext} from '../../context/adsLogicContext';
import { useAuth } from '../../context/authContext';
import { Button, Card, Form, Alert, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { BsQuestionCircle } from "react-icons/bs";
import DOMPurify from 'dompurify';

import './CreationInfoTab';

import '../../views/main.css';
import {
    doc,
    setDoc,
} from 'firebase/firestore';
import CreateInfoTab from './CreationInfoTab';

export default function BuildFromScratch() {
    const history = useNavigate();
    const [companyName, setCompanyName] = useState('')
    const [error, setError] = useState(null);
    const [showAlert, setShowAlert] = useState(true);
    const { 
        isModalVisible, 
        toggleModal, 
        timer 
    } = useAdsLogicContext();

    const {
        defaultSubscription,
        currentUser,
        handleAddResume,
        defaultResume,
        setDefaultResume,
        loading,
        setLoading,
        base,
        setBase,
        resumeContent,
        userFailsafe, 
        setUserFailsafe,
        setResumeContent
    } = useAuth();

    const closeModal = () => {
        toggleModal();
    };
    useEffect(()=>{
        if(!userFailsafe){
          setLoading(false);
          history("/ReviewResumePAge")
        }
      }, [loading, userFailsafe])
      const closeModalAndNavigate = async () => {
        setLoading(true);
        closeModal();
      };
    const handleAddCreatedScratchResume = async () => {
        if(currentUser && !loading) {
            try {
                await setDoc(doc(db, 'defaultScratchResume', `${currentUser.uid}`), {
                    title: companyName,
                    data: resumeContent
                })
            } catch (error){
                setError('Error adding extracted data to Firestore:' + error)
            }
        }
    }

    const handleSubmit = () => {
        if (
            defaultResume.firstName.trim() === '' ||
            defaultResume.lastName.trim() === '' ||
            defaultResume.jobTitle.trim() === '' ||
            defaultResume.experience.trim() === '' ||
            defaultResume.education.trim() === '' ||
            defaultResume.skills.trim() === '' ||
            defaultResume.contactInfo.trim() === ''
        ) {
            setError('All Fields Are Required');
            return;
        }
        if (defaultSubscription.name === 'Free') {
            closeModalAndNavigate();
            handleAddResume();
            generateResume(defaultResume);
        }
        else {
            setLoading(true)
            handleAddResume();
            generateResume(defaultResume);
        }
    }

    const handleChange = (field, value) => {
        const sanitizedValue = DOMPurify.sanitize(value);
        setDefaultResume({
            ...defaultResume,
            [field]: sanitizedValue,
        });
    };
    const generateResume = async (...defaultResume) => {
        const defaultVal = defaultResume[0]
        try {
            const response = await fetch(
                'https://us-central1-gpt-resume-builder.cloudfunctions.net/scratch_functions-scratch_functions',
                {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    defaultResume: defaultVal
                })
                }
            );
            if (!response.ok) {
                throw new Error('Failed to generate resume');
            }
            const data = await response.json();
            const resumeSummary = data;
            setResumeContent(resumeSummary);
            setCompanyName(`${defaultVal.firstName}.${defaultVal.lastName}.scratchResume`);
            setBase(resumeSummary)
            setLoading(false);
            setUserFailsafe(false);
            } catch (error) {
            console.error('Error generating resume:', error);
        }
    };

    const userFirstName = "Enter your first name (required):";
    const userLastName = "Enter your last name (required):";
    const userTitle = "Provide your most recent job title (required):";
    const userExperience = "Detail your relevant job history (required):";
    const userEducation = "List your education and certifications (required):";
    const userSkills = "Specify your relevant skills (required):";
    const userContactInformation = "Share your contact information (email, phone, portfolio, etc.) (required):";
    const userAdditional = "Include any additional relevant information (optional):";
    
    
    useEffect(() => {
        if(base){
            handleAddCreatedScratchResume(resumeContent, companyName)
        }
    }, [base])
    useEffect(() => {
        const alertTimeout = setTimeout(() => {
            setShowAlert(false);
        }, 10000);
        return () => clearTimeout(alertTimeout);
    }, []);
    return (
        <>
        {error && (
        <Alert variant="danger" className='alerts' dismissible>
            <Alert.Heading>Error</Alert.Heading>
            <p>{error}</p>
            </Alert>
        )}
        {isModalVisible && 
            <div className='showAdsModal'>
                <div className='showAdsWrap'>
                    <div className='adsVideoPlayer'>
                        <h3>Brewing up a Resume</h3>
                    </div>
                    <div className='showAdsNav'>
                        <div>
                            <p>want to get more out of your resume</p>
                            <Link to="/Subscriptions">
                                <Button aria-label='Upgrade subscription button'>
                                    Upgrade
                                </Button>
                            </Link>
                        </div>
                        <div>
                            <div className='timer'>{timer} seconds remaining</div>
                            <Button 
                                aria-label='close ad modal button'
                                onClick={() => {
                                closeModal(); 
                                history('/ReviewResumePage')
                                }} 
                                disabled={timer > 0 }>
                                Close
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        }
        {showAlert && 
            <Alert variant='warning'className='alerts' dismissible>
                <Alert.Heading>
                    Alert
                </Alert.Heading>
                <p>This Component will not function properly with adBlocker, please disable your adBlocker for this site.</p>
            </Alert>
        }
        <div>
            <CreateInfoTab />
            <div className='pageWrapper'>
                <h3>Step 1</h3>
                <div className='cardInnerDiv' id='scratchCardInnerDiv'>
                <Card>
                    <Card.Body>
                        <div className='ScratchInnerDiv'>
                            <h3>Create Base Resume</h3>
                            <p  className="required-note">Fields marked with <span className="required-indicator">*</span> are required.</p>
                            <Form>
                                <div>
                                    <Form.Group>
                                        <Form.Label className="d-flex justify-content-between align-items-center">
                                        <div className="text-center">
                                            First Name<span className="required-indicator">*</span>{" "}
                                            <OverlayTrigger
                                            overlay={<Tooltip id="tooltip">{userFirstName}</Tooltip>}
                                            >
                                            <span className="question-icon">
                                                <BsQuestionCircle />
                                            </span>
                                            </OverlayTrigger>
                                        </div>
                                            <sub>({defaultResume.firstName.length} / 25)</sub>
                                        </Form.Label>
                                        <Form.Control 
                                        type='text' 
                                        placeholder='first name'
                                        value={defaultResume.firstName}
                                        onChange={(e) => handleChange('firstName', e.target.value)}
                                        maxLength='50'
                                        className="mobile-textarea"
                                        />
                                        <br/>
                                        <Form.Label className="d-flex justify-content-between align-items-center">
                                            <div className='text-centered'>
                                                Last Name<span className="required-indicator">*</span> {" "}
                                                <OverlayTrigger 
                                                overlay={
                                                <Tooltip id="tooltip">
                                                    {userLastName}
                                                </Tooltip>
                                                }>
                                                <span className="question-icon">
                                                    <BsQuestionCircle />
                                                </span>
                                                </OverlayTrigger>
                                            </div>
                                            <sub>({defaultResume.lastName.length} / 25)</sub>
                                        </Form.Label>
                                        <Form.Control 
                                        type='text' 
                                        placeholder='last name'
                                        value={defaultResume.lastName}
                                        onChange={(e) => handleChange('lastName', e.target.value)}
                                        maxLength='25'
                                        className="mobile-textarea"
                                        />
                                        <br/>
                                        <Form.Label className="d-flex justify-content-between align-items-center">
                                            <div className='text-centered'>
                                                Job Title<span className="required-indicator">*</span> {" "}
                                                <OverlayTrigger 
                                                overlay={
                                                <Tooltip id="tooltip">
                                                    {userTitle}
                                                </Tooltip>
                                                }>
                                                <span className="question-icon">
                                                    <BsQuestionCircle />
                                                </span>
                                                </OverlayTrigger>
                                            </div>
                                            <sub>({defaultResume.jobTitle.length} / 25)</sub>
                                        </Form.Label>
                                        <Form.Control 
                                        type='text' 
                                        placeholder='Current Job Title'
                                        value={defaultResume.jobTitle}
                                        onChange={(e) => handleChange('jobTitle', e.target.value)}
                                        maxLength='25'
                                        className="mobile-textarea"
                                        />
                                    </Form.Group>
                                    <br/>
                                </div>
                                <Form.Group>
                                    <Form.Label className="d-flex justify-content-between align-items-center">
                                        <div className='text-centered'>
                                        Experience<span className="required-indicator">*</span> {" "}
                                            <OverlayTrigger 
                                                overlay={
                                                <Tooltip id="tooltip">
                                                    {userExperience}
                                                </Tooltip>
                                                }>
                                                <span className="question-icon">
                                                    <BsQuestionCircle />
                                                </span>
                                            </OverlayTrigger>
                                        </div>
                                        <sub>({defaultResume.experience.length} / 1800)</sub>
                                    </Form.Label>
                                    <Form.Control 
                                    as='textarea' 
                                    rows={5} 
                                    placeholder='Experience'
                                    value={defaultResume.experience}
                                    onChange={(e) => handleChange('experience', e.target.value)}
                                    maxLength='1800'
                                    className="mobile-textarea"
                                    />
                                    <Form.Text className='text-muted'>
                                        Work Experience, this should include: Dates of Employment, Employer, Job Title, and a brief description of your role.
                                    </Form.Text>
                                </Form.Group>
                                <br/>
                                <Form.Group>
                                    <Form.Label className="d-flex justify-content-between align-items-center">
                                        <div className='text-centered'>
                                            Education<span className="required-indicator">*</span> {" "}
                                            <OverlayTrigger 
                                                overlay={
                                                <Tooltip id="tooltip">
                                                    {userEducation}
                                                </Tooltip>
                                                }>
                                                <span className="question-icon">
                                                    <BsQuestionCircle />
                                                </span>
                                            </OverlayTrigger>
                                        </div>
                                        <sub>({defaultResume.education.length} / 125)</sub>
                                    </Form.Label>
                                    <Form.Control 
                                    as='textarea' 
                                    rows={3} 
                                    placeholder='Education'
                                    value={defaultResume.education}
                                    onChange={(e) => handleChange('education', e.target.value)}
                                    maxLength='125'
                                    className="mobile-textarea"
                                    />
                                    <Form.Text className='text-muted'>
                                        Education and Certifications
                                    </Form.Text>
                                </Form.Group>
                                <br/>
                                <Form.Group>
                                    <Form.Label className="d-flex justify-content-between align-items-center">
                                        <div className='text-centered'>
                                            Skills<span className="required-indicator">*</span> {" "}
                                            <OverlayTrigger 
                                                overlay={
                                                <Tooltip id="tooltip">
                                                    {userSkills}
                                                </Tooltip>
                                                }>
                                                <span className="question-icon">
                                                    <BsQuestionCircle />
                                                </span>
                                            </OverlayTrigger>
                                        </div>
                                        <sub>({defaultResume.skills.length} / 125)</sub>
                                    </Form.Label>
                                    <Form.Control 
                                    as='textarea' 
                                    rows={2} 
                                    placeholder='Skills'
                                    value={defaultResume.skills}
                                    onChange={(e) => handleChange('skills', e.target.value)}
                                    maxLength='125'
                                    className="mobile-textarea"
                                    />
                                    <Form.Text className='text-muted'>
                                        Skills relevant to Employment
                                    </Form.Text>
                                </Form.Group>
                                <br/>
                                <Form.Group>
                                    <Form.Label className="d-flex justify-content-between align-items-center">
                                        <div className='text-centered'>
                                            Contact Information<span className="required-indicator">*</span> {" "}
                                            <OverlayTrigger 
                                                overlay={
                                                <Tooltip id="tooltip">
                                                    {userContactInformation}
                                                </Tooltip>
                                                }>
                                                <span className="question-icon">
                                                    <BsQuestionCircle />
                                                </span>
                                            </OverlayTrigger>
                                        </div>
                                        <sub>({defaultResume.contactInfo.length} / 250)</sub>
                                    </Form.Label>
                                    <Form.Control 
                                    as='textarea' 
                                    rows={3} 
                                    placeholder='Contact Information'
                                    value={defaultResume.contactInfo}
                                    onChange={(e) => handleChange('contactInfo', e.target.value)}
                                    maxLength='250'
                                    className="mobile-textarea"
                                    />
                                    <Form.Text className='text-muted'>
                                        Email, Phone Number, and any Social Media Accounts you want to share with your Potential Employer
                                    </Form.Text>
                                </Form.Group>
                                <br/>
                                <Form.Group>
                                    <Form.Label className="d-flex justify-content-between align-items-center">
                                        <div className='text-centered'>
                                        Anything Additional {" "}
                                            <OverlayTrigger 
                                                overlay={
                                                <Tooltip id="tooltip">
                                                    {userAdditional}
                                                </Tooltip>
                                                }>
                                                <span className="question-icon">
                                                    <BsQuestionCircle />
                                                </span>
                                            </OverlayTrigger>
                                        </div>
                                        <sub>({defaultResume.additional.length} / 250)</sub>
                                    </Form.Label>
                                    <Form.Control 
                                    as='textarea' 
                                    rows={3} 
                                    placeholder='Additional Information' 
                                    value={defaultResume.additional}
                                    onChange={(e) => handleChange('additional', e.target.value)}
                                    maxLength='250'
                                    className="mobile-textarea"
                                    />
                                    <Form.Text className='text-muted'>
                                        Any additional information you think may be beneficial
                                    </Form.Text>
                                </Form.Group>
                            </Form>
                            
                        </div>
                    </Card.Body>
                </Card>
                <Button 
                aria-label='submit button'
                onClick={handleSubmit}
                >
                    Submit
                </Button>
                </div>
                <br/>
            </div>
        </div>
        </>
    )
}