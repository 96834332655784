import React from 'react'
import Header from '../../components/header';
import Footer from '../../components/footer';
import SelectNewAvatar from '../../components/User/selectNewAvatar';
import ChangeAvatarWindow from '../../components/User/changeAvatarWindow';


export default function profilePage() {
  return (
    <>
    <Header />
      <div>
          <ChangeAvatarWindow />
          <SelectNewAvatar />
      </div>
      <Footer />
    </>
  )
}
