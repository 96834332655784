import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import {useAuth} from '../../context/authContext';

import '../../views/main.css';

export default function ReviewInformationalTab() {
    const {
        setCurrentResumeStyle,
        defaultSubscription
    } = useAuth();

    const [style, setStyle] = useState(false);
    function isStyling() {
        setStyle((prevStyle) => !prevStyle)
    }

    return (
        <div className='floatingDiv'>
        {!style ? (
            <>
            <p>
                This response is limited to a single page.
            <br/>
            <br/>
                Ensure the accuracy of information before saving.
            </p>
            <div>
            <Button 
            aria-label='styles button'
            onClick={isStyling}
            >
                Styles
            </Button>
            </div>
            </>
        ) : (
            <>
            <div>
                <ul>
                    <li>
                        <h6>Default</h6>
                        <Button 
                        aria-label='change style to default button'
                        onClick={() => setCurrentResumeStyle(0)}
                        >
                            Style 1
                        </Button>
                    </li>
                    <li>
                        <h6>Sleek Sophistication</h6>
                        <Button 
                        aria-label='change style to Sleek Sophistication button'
                        onClick={() => setCurrentResumeStyle(1)}
                        >
                            Style 2
                        </Button>
                    </li>
                    <li>
                        <h6>Professional Prodigy 
                            {/* {
                            defaultSubscription.name !== 'Premium' &&
                            defaultSubscription.name !== 'Premium (Annual)'
                        && (<p>(Premium)</p>)} */}
                        </h6>
                        <Button 
                        aria-label='change style to Professional Prodigy button'
                        // disabled={
                        //     defaultSubscription.name !== 'Premium' ||
                        //     defaultSubscription.name !== 'Premium (Annual)'
                        // }
                        onClick={() => setCurrentResumeStyle(2)}
                        >
                            Style 3
                        </Button>
                    </li>
                    <li>
                        <h6>Elegant Edge 
                            {/* {
                            defaultSubscription.name !== 'Premium' &&
                            defaultSubscription.name !== 'Premium (Annual)'
                        && (<p>(Premium)</p>)} */}
                        </h6>
                        <Button 
                        aria-label='change style to Elegant Edge button'
                        // disabled={
                        //     defaultSubscription.name !== 'Premium' ||
                        //     defaultSubscription.name !== 'Premium (Annual)'
                        // }
                        onClick={() => setCurrentResumeStyle(3)}
                        >
                            Style 4
                        </Button>
                    </li>
                    <li>
                        <h6>Executive Excellence 
                            {/* {
                            defaultSubscription.name !== 'Premium' &&
                            defaultSubscription.name !== 'Premium (Annual)'
                        && (<p>(Premium)</p>)} */}
                        </h6>
                        <Button 
                        aria-label='change style to Executive Excellence button'
                        // disabled={
                        //     defaultSubscription.name !== 'Premium' ||
                        //     defaultSubscription.name !== 'Premium (Annual)'
                        // }
                        onClick={() => setCurrentResumeStyle(4)}
                        >
                            Style 5
                        </Button>
                    </li>
                </ul>
            </div>
            <div>
            <Button 
            aria-label='back button'
            onClick={isStyling}
            >
                Back
            </Button>
            </div>
            </>
        )}
        </div>
    )
}
