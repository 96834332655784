import React from 'react';
import './main.css';

export default function FourOFour() {
  return (
    <div>
        <h1>Out Of Mana</h1>
        <h4>Error 404</h4>
        <p>Page cannot be found</p>
    </div>
  )
}
