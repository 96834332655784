import React, {useEffect, useState, Suspense, lazy } from 'react';
import { usePDF } from 'react-to-pdf';
import { Button, Card, Alert } from 'react-bootstrap'
import './main.css';
import {useAuth} from '../context/authContext';

import Header from '../components/header';
import Footer from '../components/footer';
import ReviewResumeTablePremium from '../components/Review/reviewResumeTablePremium';
import EditResume from '../components/Review/editResume';
import ReviewInformationalTab from '../components/Review/reviewInformationalTab';
import LoadingPage from '../components/loadingPage';
import SwitchBetweenBaseAndFromJobDesc from '../components/Review/switchBetweenBaseAndFromJobDesc';
import ReviewCoverLetter from '../components/Review/reviewCoverLetter';

const DefaultResumeStyle = lazy(() => import('../components/Review/resumeStyles/defaultResumeStyle'));
const ResumeStyle2 = lazy(() => import('../components/Review/resumeStyles/resumeStyle2'));
const ResumeStyle3 = lazy(() => import('../components/Review/resumeStyles/resumeStyle3'));
const ResumeStyle4 = lazy(() => import('../components/Review/resumeStyles/resumeStyle4'));
const ResumeStyle5 = lazy(() => import('../components/Review/resumeStyles/resumeStyle5'))

export default function ReviewResumePage() {
    const {
        currentResumeStyle,
        handleAddCorrectedBaseResume,
        handleAddCorrectedFromJobDescResume,
        setCorrected,
        setCorrectedFromJobDesc,
        resumeData,
        coverLetterContent,
        edit,
        setEdit,
        error
    } = useAuth();
    const [isGenPDF, setIsGenPDF] = useState(false);
    const resumeStyleProps ={
        isGenPDF
    }
    const { toPDF, targetRef } = usePDF({filename:`${resumeData.firstName}_${resumeData.lastName}_Resume.pdf`});
    const handleToPDF = async () => {
        await setIsGenPDF(true);
        toPDF();
        setIsGenPDF(false);
    };
    const [headerContent, setHeaderContent] = useState(null);
    function isEdit() {
        setEdit((prevEdit) => !prevEdit);
    }
    useEffect(()=> {
        const correctHeader = () => {
            if(resumeData.version === 'correctedBase' || resumeData.version === 'scratchResume'){
                return (
                    <h2>
                        Base Resume
                    </h2>
                )
            }
            if(resumeData.version === 'fromJobDesc' || resumeData.version === 'correctedFromJobDesc'){
                return(
                    <h2>
                        Resume from Job Description
                    </h2>
                )
            }
            return null
        };
        const headerContent = correctHeader();
        setHeaderContent(headerContent);
    }, [resumeData])

    function ResumeStyleChoices() {
        if(currentResumeStyle === 0){
            return (
                <Suspense fallback={<LoadingPage/>}>
                    <DefaultResumeStyle {...resumeStyleProps}/>
                </Suspense>
            )
        }
        else if(currentResumeStyle === 1) {
            return (
                <Suspense fallback={<LoadingPage/>}>
                    <ResumeStyle2 {...resumeStyleProps}/>
                </Suspense>
            )
        }
        else if(currentResumeStyle === 2) {
            return (
                <Suspense fallback={<LoadingPage/>}>
                    <ResumeStyle3 {...resumeStyleProps}/>
                </Suspense>
            )
        }
        else if(currentResumeStyle === 3) {
            return (
                <Suspense fallback={<LoadingPage/>}>
                    <ResumeStyle4 {...resumeStyleProps}/>
                </Suspense>
            )
        }
        else if(currentResumeStyle === 4) {
            return (
                <Suspense fallback={<LoadingPage/>}>
                    <ResumeStyle5 {...resumeStyleProps}/>
                </Suspense>
            )
        }
    }
    function checkResumeVersion() {
        if(resumeData.version === "fromJobDesc" || resumeData.version === 'correctedFromJobDesc'){
            handleAddCorrectedFromJobDescResume()
            setCorrectedFromJobDesc(resumeData)
        }
        else {
            handleAddCorrectedBaseResume()
            setCorrected(resumeData)
        }
    }
    return (
        <>
        {console.log(resumeData)}
        {error && (
            <Alert variant='danger'  className='alerts' dismissible>
                <Alert.Heading>Error</Alert.Heading>
                <p>{error}</p>
            </Alert>
        )}
        <Header />
        <div className='pageWrapper'>
            {headerContent}
            {!edit ? (
                <>
                <ReviewInformationalTab />
                </>
            ) : (
                <>
                </>
            )}
            <SwitchBetweenBaseAndFromJobDesc />
            <div className='reviewDefaultResumeCardWrap'>
                <Card>
                    <Card.Body>
                        {!edit ? (
                            <>
                            <div ref={targetRef}>
                                {ResumeStyleChoices()}
                            </div>
                            <div className='reviewButtonGroup'>
                                <Button 
                                aria-label='edit button'
                                onClick={isEdit}
                                >
                                    Edit
                                </Button>
                                <Button 
                                aria-label='save button'
                                onClick={() => checkResumeVersion()}
                                >
                                    Save
                                </Button>
                                <Button 
                                aria-label='download pdf button'
                                onClick={() => handleToPDF()}
                                >
                                    Download
                                </Button>
                            </div>
                            </>
                        ) : (
                            <>
                            <EditResume />
                            <div className='reviewButtonGroup'>
                                <Button 
                                aria-label='continue button'
                                onClick={isEdit}
                                >
                                    Continue
                                </Button>
                            </div>
                            </>
                        )}
                    </Card.Body>
                </Card>
                {coverLetterContent && (
                    <ReviewCoverLetter />
                )}
            </div>
            <ReviewResumeTablePremium />
        </div>
        <Footer />
        </>
    )
}
