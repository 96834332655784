import React, { useEffect } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import { Card, Alert } from 'react-bootstrap';
import { useAuth } from '../context/authContext';
import { db } from '../server/firebaseConfig';
import { doc, onSnapshot, getDoc } from 'firebase/firestore';

import './main.css';

export default function SubscriptionPage() {
    const {
        defaultSubscription,
        setDefaultSubscription,
        error,
        setError,
        currentUser,
        setLoading,
        loading
    } = useAuth();
    const pricingTableId = "prctbl_1OSlGhEsYMRYIVaA4H7sJnT2";

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://js.stripe.com/v3/pricing-table.js";
        script.async = true;
        script.onload = () => {
            setLoading(false);
        };
        document.body.appendChild(script);
        const fetchSubscription = async () => {
            if (currentUser && !loading) {
                try {
                    const docRef = doc(db, 'userSubscription', `${currentUser.uid}`);
                    const fetchedDoc = await getDoc(docRef);
                    const docSnapshot = fetchedDoc.data()
                    if (docSnapshot) {
                        const subscriptionData = docSnapshot.defaultSubscription;
                        if (subscriptionData) {
                            setDefaultSubscription(subscriptionData);
                        }
                    }
                } catch (err) {
                    setError('There was an error fetching the subscription: ' + err);
                }
            }
        };
        fetchSubscription();
        return () => {
            document.body.removeChild(script);
        };
    }, [currentUser, setError, setLoading, loading]);

    useEffect(() => {
        const docRef = doc(db, 'userSubscription', `${currentUser.uid}`);
        const unsubscribe = onSnapshot(docRef, (doc) => {
            if (doc.exists) {
                const subscriptionData = doc.data().defaultSubscription;
                if (subscriptionData) {
                    setDefaultSubscription(subscriptionData);
                }
            }
        });

        return () => {
            unsubscribe();
        };
    }, [currentUser]);

    useEffect(() => {
        const stripeTable = document.querySelector('stripe-pricing-table');
        if (stripeTable) {
            stripeTable.addEventListener('successful-subscription', handleSuccessfulSubscription);
        }
        return () => {
            if (stripeTable) {
                stripeTable.removeEventListener('successful-subscription', handleSuccessfulSubscription);
            }
        };
    }, []);
    

    const handleSuccessfulSubscription = async (event) => {
        const subscriptionData = event.detail.subscription;
        try {
            await db.collection('customers').doc(currentUser.uid).update({
                subscription: subscriptionData,
            });
        } catch (error) {
            setError('Error updating database:' + error);
        }
    };

    return (
        <>
            {error && (
                <Alert variant='danger' className='alerts' dismissible>
                    <Alert.Heading>Error</Alert.Heading>
                    <p>{error}</p>
                </Alert>
            )}
            <Header />
            <div className='pageWrapper'>
                <div className='subscriptionPageWrap'>
                    <Card>
                        <Card.Body>
                            <div className='tiersSegments'>
                                <div id="subLeftTierSegment">
                                    <div>
                                        <h3>Current Tier</h3>
                                        <h5>{defaultSubscription.name}</h5>
                                    </div>
                                    <div>
                                        <h5>Description</h5>
                                        <p>{defaultSubscription.description}</p>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card id='paymentProcessorCard'>
                        <Card.Body>
                            <div className='tiersSegments' id='paymentProcessorDiv'>
                                <stripe-pricing-table
                                    pricing-table-id={pricingTableId}
                                    publishable-key={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
                                >
                                </stripe-pricing-table>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <Footer />
        </>
    );
}
