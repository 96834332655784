import React, { useState } from 'react';

import '../../views/main.css';

export default function CreateInfoTab() {

    return (
        <div className='floatingDiv'>
            <div>
                Stay concise while allowing the AI to refine the content for optimal impact.
            </div>
        </div>
    )
}
