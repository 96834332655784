import React, { useState, useRef } from 'react';
import {useAuth} from '../context/authContext';
import { Link, useNavigate  } from 'react-router-dom';
import { Button, Card, Form, Alert} from 'react-bootstrap';

import '../components/loginComponents/login.css';

import LoginHeader from '../components/loginComponents/loginHeader'
require('firebase/auth');


export default function RegisterUser() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const history = useNavigate();
    const {signup} = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    
    const validatePassword = () => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordRegex.test(passwordRef.current.value);
    };
    
    async function handleSubmit(e) {
        e.preventDefault();
        if(passwordRef.current.value !== passwordConfirmRef.current.value){
            return setError('Passwords do not match');
        }
        if (!validatePassword()) {
            return setError('Password must have at least 8 characters, including 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character.');
        }
        try {
            setError('')
            setLoading(true)
            await signup(emailRef.current.value, passwordRef.current.value)
            history('/')
        } 
        catch(err) {
            setError('Failed to create account ' + err)
        }
        setLoading(false)
    }

    return (
        <>
        <LoginHeader />
        {error && (
            <Alert variant='danger' className='alerts' id='registrationError' dismissible>
                <Alert.Heading>
                    Error
                </Alert.Heading>
                {error}
            </Alert>
        )}
        <div className="RegisterWrap">
            <Card>
                <Card.Body>
                    <h2 className='text-center mb-4'>Sign Up</h2>
                    
                    
                    <Form onSubmit={handleSubmit}>
                        <Form.Group id='email'>
                            <Form.Label>Email</Form.Label>
                            <Form.Control type='email' ref={emailRef} required/>
                        </Form.Group>
                        <Form.Group id='password'>
                            <Form.Label>Password</Form.Label>
                            <Form.Control type='password' ref={passwordRef} required/>
                        </Form.Group>
                        <Form.Group id='password-confirm'>
                            <Form.Label>Password Confirmation</Form.Label>
                            <Form.Control type='password' ref={passwordConfirmRef} required/>
                        </Form.Group>
                        <br/>
                        <Button 
                        aria-label='Register button'
                        disabled={loading} 
                        className='w-100' 
                        type='Submit'
                        >
                            Sign Up
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
            <div className="w-100 text-center mt-2">
            Already have an Account?
            <br/>
            <Link to='/SignIn'>
                Login
            </Link>
            </div>
        </div>
        </>
    )
}