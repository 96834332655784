import React, {useState} from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import {BiCopy} from 'react-icons/bi';

import '../../views/main.css';
import { useAuth } from '../../context/authContext';


export default function ReviewCoverLetter() {
    const {
        coverLetterContent,
        setCoverLetterContent,
        setError
    } = useAuth();
    const [editCoverLetter, setEditCoverLetter] = useState(false);

    function isEditCoverLetter() {
        setEditCoverLetter((prevEdit) => !prevEdit);
    }

    const handleCoverLetterChanges = () => {
        setCoverLetterContent()
    }
    const renderTextWithNewLines = (text) => {
        if (text) {
            const lines = text.split('\n');
            return lines.map((line, index) => {
                return (
                    <p key={index}>{line}</p>
                );
            });
        }
        return null;
    };
    
    async function copyToText(){
        try {
            var copiedStuff = document.getElementById("copyThisText");
            var textToCopy = copiedStuff.textContent;
            await navigator.clipboard.writeText(textToCopy);
            alert("Copied to Clipboard");
        }
        catch (err) {
            setError("Failed to copy to clipboard: " + err);
        }
    }

    function showCoverLetter(){
        if(editCoverLetter === false){
            return (
                <div className='reviewCoverLetterComponent'>
                    <Card>
                        <Card.Body>
                            <h2 className='coverLetterCentered'>
                                Cover Letter
                            </h2>
                            <div className='cardInnerDiv'>
                                <div id='copyThisText'>{renderTextWithNewLines(coverLetterContent)}</div>
                            </div>
                            <div className='coverLetterCentered'>
                                <Button 
                                aria-label='edit button'
                                onClick={isEditCoverLetter} 
                                >
                                    Edit
                                </Button>
                                <BiCopy 
                                onClick={() => copyToText()} 
                                id='biCopyButton'
                                />
                            </div>
                            
                        </Card.Body>
                    </Card>
                </div>
            )}
        else {
            return (
                <div>
                    <Card>
                        <Card.Body>
                            <Form.Group>
                                <Form.Label  className='coverLetterCentered'>
                                    Cover Letter
                                </Form.Label>
                                <Form.Control 
                                as='textarea'
                                rows={8}
                                placeholder='Cover Letter Content'
                                value={coverLetterContent}
                                onChange={(e) => handleCoverLetterChanges(e.target.value)}
                                />
                            </Form.Group>
                            <div className='coverLetterCentered'>
                                <Button 
                                aria-label='continue button'
                                onClick={isEditCoverLetter}
                                >
                                    continue
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            )
        }
    }
    return (
        <>
            {showCoverLetter()}
        </>
    )
}
