import React, {useState} from 'react';
import './App.css';
import PrivateRoute from './components/privateRoute';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/authContext';
import {AdsBuilderProvider} from './context/adsLogicContext';
import Home from './views/home';
import Signin from './views/signin';
import Signup from './views/signup';
import ForgotPassword from './views/forgotPassword';
import Create from './views/create';
import ReviewResumePage from './views/reviewResume';
import ProfilePage from './views/UserProfile/profilePage';
import SubscriptionPage from './views/subscriptionPage';
import FourOFour from './views/404';
import {ThemeProvider} from 'styled-components';
import PrivacyPolicyPage from './views/privacyPolicyPage';


function App() {
  const [theme, setTheme] = useState({mode: 'light'})
  return (
    <BrowserRouter>
      <AuthProvider>
      <AdsBuilderProvider >
      <ThemeProvider theme={theme}>
      <React.Fragment>
            <Routes>
              <Route path="/SignUp" element={<Signup />} />
              <Route exact path="/" element={<Home />} />
              <Route exact path="/Create" element={<PrivateRoute><Create /></PrivateRoute>} />
              <Route exact path="/ReviewResumePage" element={<PrivateRoute><ReviewResumePage /></PrivateRoute>} />
              <Route exact path="/Profile" element={<PrivateRoute><ProfilePage /></PrivateRoute>} />
              <Route exact path="/Subscriptions" element={<PrivateRoute><SubscriptionPage /></PrivateRoute>} />
              <Route path="/SignIn" element={<Signin />} />
              <Route path="/forgotPassword" element={<ForgotPassword/>}/>
              <Route path='/PrivacyPolicyPage' element={<PrivacyPolicyPage/>} />
              <Route path='*' element={<FourOFour/>}/>
            </Routes>
        </React.Fragment>
        </ThemeProvider>
        </AdsBuilderProvider>
        </AuthProvider>
      </BrowserRouter>
  );
}

export default App;
