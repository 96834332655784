import React from 'react';
import {useAuth} from '../../context/authContext';
import {Button} from 'react-bootstrap';
import './selectNewAvatar.css';
import SubscriptionLevel from './subscriptionLevel';

export default (props) => {
    const {
        defaultUserPic, 
        profilePicBkg,
        avatarbkgClr,
        profilePicBkgClr, 
        loading,
    } = useAuth();
    
    const showChangeAvatarWindow = () => {
        const x = document.getElementsByClassName('showChangeAvatarWindow')[0];
        if(x.style.display == 'block') {
            x.style.display = 'none';
        }
        else {
            x.style.display = 'block';
        }
    }


    return (
        <div className="selectAvatarWrap">
            <div className="selectAvatarLeft">
                <div className='avatarBkg' style={{backgroundColor: profilePicBkgClr}}>
                    <img src={defaultUserPic} alt='selected avatar pic'/>
                </div>
                <Button 
                    aria-label='change avatar button'
                    disabled={loading} 
                    onClick={showChangeAvatarWindow}
                >
                    Change Avatar
                </Button>
                <br/><h5>Select Background</h5>
                <div className='selectBkgClrButtons'>
                {profilePicBkg.key.map((color, bkgIndex) => (    
                <Button 
                aria-label='select Avatar'
                key={bkgIndex}
                style={{backgroundColor: color}} 
                className='selectBkgClrButton'
                    onClick={async (e) => {
                        await avatarbkgClr(bkgIndex)
                    }}
                >
                    Select
                </Button>
                ))}
                </div>
            </div>
            <div className='selectAvatarRight'>
                <SubscriptionLevel/>
            </div>
        </div>
    )
}