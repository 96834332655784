import React from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import {useAuth} from '../context/authContext';


import BuildFromScratch from '../components/resumeCreationComponents/buildFromScratch';
import NewResumePage from '../components/resumeCreationComponents/newResumePage';
import './main.css';

export default function Create() {
    const {
        resumeData,
        error
    } = useAuth();
    


    const determineIfNetNewResume = (resumeData) => {
        if (!resumeData.firstName ||
            !resumeData.lastName ||
            !resumeData.jobTitle ||
            !resumeData.contactInfo ||
            !resumeData.summary ||
            !resumeData.experience ||
            !resumeData.education ||
            !resumeData.skills) {
            return <BuildFromScratch />;
        } else {
            return <NewResumePage />;
        }
    }
  return (
    <>
    <Header />
    {determineIfNetNewResume(resumeData)}
    <Footer />
    </>
  )
}
