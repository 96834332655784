import React from 'react'
import { Button, Card } from 'react-bootstrap'
import '../../views/main.css';
import {useAuth} from '../../context/authContext';
export default function ReviewResumeTablePremium() {

    const {
        defaultSubscription,
        listResumeData,
        resumeData,
        setEdit,
        setResumeData,
        breakResumeIntoSections
    } = useAuth();

    function isEdit() {
        setEdit((prevEdit) => !prevEdit);
    }
    const handleViewClick = (resume) => {
        const sections = breakResumeIntoSections(resume)
        setResumeData({
            firstName: resumeData.firstName,
            lastName: resumeData.lastName,
            jobTitle: resumeData.jobTitle,
            contactInfo: sections['Contact Info:'],
            summary: sections['Summary:'],
            experience: sections['Experience:'],
            education: sections['Education and Certifications:'],
            skills: sections['Skills:'],
            version: 'fromJobDescTable'
        })
    }
    const renderTableRows = () => {
        return listResumeData.map((resume, index) => (
            <tr key={index}>
                <td>{resume.timestamp.toDate().toLocaleDateString()}</td>
                <td>{resume.companyName}</td>
                <td>{resume.companyName}.{resumeData.firstName}.{resumeData.lastName}</td>
                <td>
                    <Button 
                    aria-label='edit button'
                    className="btn btn-warning"
                    onClick={()=> {handleViewClick(resume); isEdit()}}
                    >
                        Edit
                    </Button>
                </td>
                <td>
                    <Button
                    aria-label='view button'
                    onClick={()=> {handleViewClick(resume)}}
                    >
                        View
                    </Button>
                </td>
            </tr>
        ));
    };
// database storage feature only available for premium accounts.
// Data should be cleared after 60 days if they unsubscribe without resubscribing during that timeframe
    
    const showReviewCard = () => {
        if(defaultSubscription && defaultSubscription.name == "Premium (Annual)" || defaultSubscription.name == "Premium"){
            return (
                <div>
                <Card>
                    <Card.Body className='reviewCardBody'>
                        <div className='cardInnerDiv' id='reviewCardInnerDiv'>
                            <h3>Resumes</h3>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Company Name</th>
                                    <th scope="col">File Name</th>
                                    <th scope='col'>Edit</th>
                                    <th scope="col">View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderTableRows()}
                                </tbody>
                            </table>
                        </div>
                    </Card.Body>
                </Card>
                <br/>
            </div>
            )
        } 
    }
  return (
    <>
        {showReviewCard()}
    </>
  )
}
