import React, {useState} from 'react';
import Linkedin from '../static/socialIcons/linkedin-3-32.png';
import FaceBook from '../static/socialIcons/facebook-3-32.png';

import './footer.css';
import { Link } from 'react-router-dom';
import Faq from '../views/legalPages/faq';
import ContactUs from '../views/legalPages/contactUs';
import PersonalizedAds from '../views/legalPages/personalizedAds';
import PrivacyPolicy from '../views/legalPages/privacyPolicy';
import TermsOfUse from '../views/legalPages/termsOfUse';

export default function Footer() {

  const [faqModalVisible, setFaqModalVisible] = useState(false);
  const closeFaqModal = () => {
    setFaqModalVisible(false);
  };
  const toggleFaqModal = () => {
    setFaqModalVisible(!faqModalVisible);
  };

  const [contactModalVisible, setContactModalVisible] = useState(false);
  const closeContactModal = () => {
    setContactModalVisible(false);
  };
  const toggleContactModal = () => {
    setContactModalVisible(!contactModalVisible);
  };

  const [adsModalVisible, setAdsModalVisible] = useState(false);
  const closeAdsModal = () => {
    setAdsModalVisible(false);
  };
  const toggleAdsModal = () => {
    setAdsModalVisible(!adsModalVisible);
  };

  const [termsModalVisible, setTermsModalVisible] = useState(false);
  const closeTermsModal = () => {
    setTermsModalVisible(false);
  };
  const toggleTermsModal = () => {
    setTermsModalVisible(!termsModalVisible);
  };

  const [privacyModalVisible, setPrivacyModalVisible] = useState(false);
  const closePrivacyModal = () => {
    setPrivacyModalVisible(false);
  };
  const togglePrivacyModal = () => {
    setPrivacyModalVisible(!privacyModalVisible);
  };

  return (
    <>
    {faqModalVisible && <Faq faqModalVisible={faqModalVisible} closeFaqModal={closeFaqModal} /> }
    {contactModalVisible && <ContactUs contactModalVisible={contactModalVisible} closeContactModal={closeContactModal} /> }
    {adsModalVisible && <PersonalizedAds adsModalVisible={adsModalVisible} closeAdsModal={closeAdsModal} /> }
    {termsModalVisible && <TermsOfUse termsModalVisible={termsModalVisible} closeTermsModal={closeTermsModal} /> }
    {privacyModalVisible && <PrivacyPolicy privacyModalVisible={privacyModalVisible} closePrivacyModal={closePrivacyModal} /> }
    <div className='footWrap'>
      <div className='secondaryFootWrap'>
        <div className='footerLeftDiv'>
          <div className='socialIcons'>
            <a href='https://www.linkedin.com/company/resumancy/' target='_blank' alt='link to linkedin'>
              <img src={Linkedin} className='socialImg' alt='linkedin image'/>
            </a>
            {/* <a href='' target='_blank' alt='link to facebook'>
              <img src={FaceBook} className='socialImg' alt='facebook image'/>
            </a> */}
          </div>
          <Link to='' onClick={toggleContactModal}>
            CONTACT US
          </Link>
        </div>
        <div className='footerRightDiv'>
          <ul>
            <li onClick={toggleFaqModal}>
              <Link to=''>
                FAQ
              </Link>
            </li>
            <li>
              <Link to='' onClick={toggleTermsModal}>
                Terms of Use
              </Link>
            </li>
            <li>
              <Link to='' onClick={togglePrivacyModal}>
                Privacy Policy
              </Link>
            </li>
            {/* <li>
              <Link to='' onClick={toggleAdsModal}>
                Personalized Ads
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
        <span className='subscript'>
          © {(new Date().getFullYear())} Resumancy. All rights reserved.
        </span>
    </div>
    </>
  )
}
