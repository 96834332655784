import React from 'react';

import '../footer.css';

export default function LoginFooter() {
    return (
        <div id='loginAndRegFoot'>
            <span className='subscript'>
            © 2023 Resumancy. All rights reserved.
            </span>
        </div>
    )
}