import React from 'react';
import { Button } from 'react-bootstrap';
import {useAuth} from '../../context/authContext';

import '../../views/main.css';

export default function SwitchBetweenBaseAndFromJobDesc() {
    const {
        resumeData,
        fetchDefaultFromJobDescResume,
        fetchCorrectedFromJobDesc,
        fetchBaseDataHierarchy,
    } = useAuth();

// figure out why switching doesn't work more than a few times
// it fails after 3 times because it is coming from a useEffect and can only be triggered twice
    const resumeDataAvailable = () => {
        if(resumeData){
            if(resumeData.version === 'fromJobDesc' || resumeData.version === 'correctedFromJobDesc'){
                return (
                    <div className='floatingLeftDiv'>
                        <p>Switch to the Base Resume</p>
                        <Button 
                        aria-label='select button'
                        onClick={() => {fetchBaseDataHierarchy()}}
                        >
                            Select
                        </Button>
                    </div>
                )
            }
            if(resumeData.version === 'correctedBase' || resumeData.version === 'scratchResume'){
                return (
                    <div className='floatingLeftDiv'>
                        <p>Switch to the Resume For the prospective job</p>
                        <Button 
                        aria-label='select button'
                        onClick={()=> {fetchDefaultFromJobDescResume()}}
                        >
                            Select
                        </Button>
                    </div>
                )
            }
        }
    };
    
    return (
        <>
        <div>
            {resumeDataAvailable()}
        </div>
        </>
    )
}
