import React, {useContext, useEffect, useState} from 'react';
import {db, auth, storage} from '../server/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import LoadingPage from '../components/loadingPage';
import { 
    createUserWithEmailAndPassword, 
    signInWithEmailAndPassword, 
    sendPasswordResetEmail
} from 'firebase/auth';
import {
    collection,
    doc,
    getDocs,
    getDoc,
    setDoc,
    addDoc,
    query,
    where,
    updateDoc,
    Timestamp,
    serverTimestamp,
} from 'firebase/firestore';

import { 
    getStorage, 
    ref, 
    uploadBytes,
    listAll,
    getDownloadURL
} from "firebase/storage";

import User from '../models/userModel';

import witchProfle from '../static/ProfilePics/WitchProfile.png';
import witchProfle2 from '../static/ProfilePics/WitchProfile2.png';
import witchProfle3 from '../static/ProfilePics/WitchProfile3.png';
import witchProfle4 from '../static/ProfilePics/WitchProfile4.png';
import witchProfle5 from '../static/ProfilePics/WitchProfile5.png';
import wizardProfile1 from '../static/ProfilePics/WizardProfile1.png';
import wizardProfile2 from '../static/ProfilePics/WizardProfile2.png';
import wizardProfile3 from '../static/ProfilePics/WizardProfile3.png';
import wizardProfile4 from '../static/ProfilePics/WizardProfile4.png';
import wizardProfile5 from '../static/ProfilePics/WizardProfile5.png';

const AuthContext = React.createContext()
export function useAuth(){
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [companyName, setCompanyName] = useState('');
    const [error, setError] = useState(null);
    const [resumeContent, setResumeContent] = useState('');
    const [listResumeData, setListResumeData] = useState([])
    const [userFailsafe, setUserFailsafe] = useState(true);
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)
    const [validationError, setValidationError] = useState(null);

    const [fetchedResumeData, setFetchedResumeData] = useState(null);

    const [sections, setSections] = useState({});

    const [correctedBaseResumeData, setCorrectedBaseResumeData] = useState(null)
    const [fetchedScratchDefaultResume, setFetchedScratchDefaultResume] = useState(null);
    const [fetchedScratchDefaultTitle, setFetchedScratchDefaultTitle] = useState(null);

    const [fetchedDefaultFromJobsDescResume, setFetchedDefaultFromJobsDescResume] = useState(null);
    const [coverLetterContent, setCoverLetterContent] = useState("");

    const history = useNavigate();
// these are used to determine which profile pic and color the user has selected
    const profilePic = {key:[witchProfle, witchProfle2, witchProfle3, witchProfle4, witchProfle5, wizardProfile1, wizardProfile2, wizardProfile3, wizardProfile4, wizardProfile5]}
    const [defaultUserPic, setDefaultUserPic] = useState(profilePic.key[0])
    const profilePicBkg = {key:['#F45B69', '#114B5F', '#456990', '#6B2737']}
    const [profilePicBkgClr, setProfilePicBkgClr] = useState(profilePicBkg.key[0])
    const [currentResumeStyle, setCurrentResumeStyle] = useState(0)
    const [edit, setEdit] = useState(false)

    // these 3 states help to determine when a function is complete 
    // and act as useEffect triggers
    // this will be used to determine which Resume is displayed
    const [base, setBase] = useState(null);
    const [corrected, setCorrected] = useState(null);
    const [fromJobDesc, setFromJobDesc] = useState(null);
    const [correctedFromJobDesc, setCorrectedFromJobDesc] = useState(null);

    const [defaultSubscription, setDefaultSubscription] = useState({
        active: true,
        description: "Dive into the world of professional resume building without limitations. Create, edit, and refine your resumes with unlimited access. However, please note that the Free Tier does not grant access to our premium services.",
        images: "",
        metadata: {
            fireabaseRole: "premium"
        },
        name: "Free",
        role: "premium",
        tax_code: "txcd_10000000"
    })

    const [defaultResume, setDefaultResume] = useState({
        firstName: '',
        lastName: '',
        jobTitle: '',
        experience: '',
        education: '',
        skills: '',
        contactInfo: '',
        additional: ''
    })
    const [resumeData, setResumeData] = useState({
        firstName: null,
        lastName: null,
        jobTitle: null,
        contactInfo: null,
        summary: null,
        experience: null,
        education: null,
        skills: null,
        version: null
    });
    
    function signup(email, password) {
        return createUserWithEmailAndPassword(auth, email, password)
    };
    function login(email, password) {
        return signInWithEmailAndPassword(auth, email, password)
    };
    function logout() {
        return auth.signOut();
    }
    function resetPassword(email) {
        return sendPasswordResetEmail(auth, email);
    }

// this function is what makes the data parsable
    const breakResumeIntoSections = (resumeContent) => {
        if (!resumeContent) {
            return {};
        }
        const sectionHeadings = [
            'Contact Info:',
            'Summary:',
            'Experience:',
            'Education and Certifications:',
            'Skills:',
            'Note:'
        ];
        const sections = {};
        let currentSection = '';
        const lines = resumeContent.split('\n');
        lines.forEach((line) => {
            const matchingHeading = sectionHeadings.find((heading) =>
                line.startsWith(heading)
            );
            if (matchingHeading) {
                currentSection = matchingHeading;
                sections[currentSection] = '';
            } else if (currentSection) {
                sections[currentSection] += `${line}\n`;
            }
        });
        for (const section in sections) {
            sections[section] = sections[section].trim();
        }
        return sections;
    };


// fetches default resume from job desc
    const fetchDefaultFromJobDescResume = async () => {
        if(currentUser && !loading) {
            try {
                const docRef = doc(db, 'defaultFromJobDescResume', `${currentUser.uid}`);
                const fetchedDoc = await getDoc(docRef);
                const companyName = fetchedDoc.get('companyName');
                const fromJobDescResumeData = fetchedDoc.get('data');
                if (companyName) {
                    setCompanyName(companyName);
                }
                if (fromJobDescResumeData) {
                    setFetchedDefaultFromJobsDescResume(fromJobDescResumeData);
                    const parsedSections = breakResumeIntoSections(
                        fromJobDescResumeData
                    );
                    setSections(parsedSections);
                }
            } catch (error) {
                setError('failed to fetch resume from job desc ' + error);
            }
        }
    }
    const fetchFromJobDescResume = async () => {
        if(currentUser && !loading) {
            try{
                const docRef = doc(db, 'fromJobDescResume', `${currentUser.uid}`);
                const fetchedDoc = await getDoc(docRef);
                const resumeData = fetchedDoc.data()
                setResumeData({
                    firstName: resumeData.firstName,
                    lastName: resumeData.lastName,
                    jobTitle: resumeData.jobTitle,
                    companyName: resumeData.companyName,
                    summary: resumeData.summary,
                    contactInfo: resumeData.contactInfo,
                    experience: resumeData.experience,
                    education: resumeData.education,
                    skills: resumeData.skills,
                    version: 'fromJobDesc'
                })
            } catch(err) {
                setError('failed to fetch resume from job desc ' + err)
            }
        }
    }
// fetches corrected resume from job desc and sets version accordingly
    const fetchCorrectedFromJobDesc = async () => {
        if(currentUser && !loading) {
            try {
                const docRef = doc(db, 'correctedFromJobDescResume', `${currentUser.uid}`);
                const fetchedDoc = await getDoc(docRef);
                const resumeData = fetchedDoc.data()
                setResumeData({
                    firstName: resumeData.firstName,
                    lastName: resumeData.lastName,
                    jobTitle: resumeData.jobTitle,
                    summary: resumeData.summary,
                    contactInfo: resumeData.contactInfo,
                    experience: resumeData.experience,
                    education: resumeData.education,
                    skills: resumeData.skills,
                    version: 'correctedFromJobDesc'
                }).then(setCorrectedFromJobDesc(resumeData))
            } catch(err) {
                setError('failed to fetch correct resume from job desc ' + err)
            }
        }
    }

// avatar and background functionality
    for (let i = 0; i < profilePicBkg.key.length; i++) {
        window[`avatarbkgClr${i}`] = () => (profilePicBkg.key[i]);
    }
    function avatarbkgClr(bkgIndex) {
        setProfilePicBkgClr(profilePicBkg.key[bkgIndex]);
    }
    for (let i = 0; i < profilePic.key.length; i++) {
        window[`UserPic${i}`] = () => (profilePic.key[i]);
    }
    function setUserPic(picIndex) {
        setDefaultUserPic(profilePic.key[picIndex]);
    }

// adding user background and profile pic
    useEffect(() => {
        handleAdd();
    }, [defaultUserPic, profilePicBkgClr]);

    const handleAdd = async () => {
        if (currentUser) {
        try { 
            const user = new User(defaultUserPic, profilePicBkgClr);
            await setDoc(doc(db, 'userSettings', `${currentUser.uid}`), {
                defaultUserPic: defaultUserPic,
                profilePicBkgClr: profilePicBkgClr,
            })
        }
        catch(error) {
            setError('failed to add userSettings ' + error)
        }}
    };

    // fetches user settings
    useEffect(() => {
        if(currentUser && !loading){
            fetchUserSettings();
        }
    }, [currentUser, defaultUserPic, profilePicBkgClr])

    const fetchUserSettings = async () => {
        if(currentUser && !loading){
            try {
                const docRef = doc(db, 'userSettings', `${currentUser.uid}`); 
                const fetchedDoc = await getDoc(docRef);
                const defaultUserPicData = fetchedDoc.get('defaultUserPic')
                const profilePicBkgClrData = fetchedDoc.get("profilePicBkgClr")
                    if(defaultUserPicData) {
                        setDefaultUserPic(defaultUserPicData)
                    }
                    if(profilePicBkgClrData) {
                        setProfilePicBkgClr(profilePicBkgClrData)
                    }
            } catch (err) {
            setError('there was an error fetching user settings ' + err);
                }
        }
    }
// this sets user subscription to default and is harmful for long term production
    useEffect(() => {
        handleAddSub();
        
    }, [currentUser, defaultSubscription]);

    const handleAddSub = async () => {
        if (currentUser) {
        try { 
            const res = await setDoc(doc(db, 'userSubscription', `${currentUser.uid}`), {
                defaultSubscription: defaultSubscription
        })
        }
        catch(error) {
            setError('there was an error adding getting the default subscription ' + error)
        }}
    };

// fetches subscription
const fetchSubscription = async () => {
    if (currentUser && !loading) {
        try {
            const docRef = doc(db, 'userSubscription', `${currentUser.uid}`);
            const fetchedDoc = await getDoc(docRef);
            const docSnapshot = fetchedDoc.data()
            if (docSnapshot) {
                const subscriptionData = docSnapshot.defaultSubscription;
                if (subscriptionData) {
                    setDefaultSubscription(subscriptionData);
                }
            }
            
        } catch (err) {
            setError('There was an error fetching the subscription: ' + err);
        }
    }
};


// single useEffect should handle fully loading data profile
    useEffect(() => {
        if (currentUser && !loading){
            fetchSubscription();
            fetchBaseDataHierarchy();
        }
    }, [currentUser, loading]);

// determines which default version is shown on after onClick
    useEffect(() => {
        if(currentUser){
            if(fetchedDefaultFromJobsDescResume){
                setResumeData({
                    firstName: resumeData.firstName,
                    lastName: resumeData.lastName,
                    jobTitle: resumeData.jobTitle,
                    companyName: companyName,
                    contactInfo: sections['Contact Info:'],
                    summary: sections['Summary:'],
                    experience: sections['Experience:'],
                    education: sections['Education and Certifications:'],
                    skills: sections['Skills:'],
                    version: 'fromJobDesc'
                });
                handleAddParsedFromJobDescriptionResume();
            }
            else {
                setResumeData({
                    firstName: defaultResume.firstName,
                    lastName: defaultResume.lastName,
                    jobTitle: defaultResume.jobTitle,
                    contactInfo: sections['Contact Info:'],
                    summary: sections['Summary:'],
                    experience: sections['Experience:'],
                    education: sections['Education and Certifications:'],
                    skills: sections['Skills:'],
                    version: 'scratchResume'
                });
            }
        }
    }, [currentUser, fetchedDefaultFromJobsDescResume, fetchedScratchDefaultResume])

// this is a parsed and then saved version of the base resume
    const handleAddResume = async () => {
        if (currentUser && !loading) {
            try{
                await setDoc(doc(db, 'userResume', `${currentUser.uid}`), {
                    firstName: defaultResume.firstName,
                    lastName: defaultResume.lastName,
                    jobTitle: defaultResume.jobTitle,
                    experience: defaultResume.experience,
                    education: defaultResume.education,
                    skills: defaultResume.skills,
                    contactInfo: defaultResume.contactInfo,
                    additional: defaultResume.additional,
                    timestamp: serverTimestamp()
            })
            }
            catch(error){
                setError('there was an error adding a resume ' + error)
            }
        }
    }

// corrected base resume upload to server
    const handleAddCorrectedBaseResume = async () => {
        if(resumeData) {
            try{
                await setDoc(doc(db, 'correctedBaseResume', `${currentUser.uid}`), {
                    firstName: resumeData.firstName,
                    lastName: resumeData.lastName,
                    jobTitle: resumeData.jobTitle,
                    summary: resumeData.summary,
                    experience: resumeData.experience,
                    education: resumeData.education,
                    skills: resumeData.skills,
                    contactInfo: resumeData.contactInfo,
                    companyName: companyName,
                    timestamp: serverTimestamp()
                })
            } catch(error){
                setError('there was an error adding corrections to the resume ' + error)
            }
        }
    }

// corrected from job desc resume upload to server 
    const handleAddCorrectedFromJobDescResume = async () => {
        if(resumeData) {
            try{
                
                await setDoc(doc(db, 'correctedFromJobDescResume', `${currentUser.uid}`), {
                    firstName: resumeData.firstName,
                    lastName: resumeData.lastName,
                    jobTitle: resumeData.jobTitle,
                    summary: resumeData.summary,
                    experience: resumeData.experience,
                    education: resumeData.education,
                    skills: resumeData.skills,
                    contactInfo: resumeData.contactInfo,
                    companyName: companyName,
                    timestamp: serverTimestamp()
                })
            } catch(error){
                console.log(error)
            }
        }
    }
// function to add non structured from job description resume to server
    const handleAddFromJobDescriptionResume = async () => {
        if(currentUser && !loading) {
            try {
                await setDoc(doc(db, 'defaultFromJobDescResume', `${currentUser.uid}`), {
                    data: fromJobDesc,
                    companyName: companyName,
                    timestamp: serverTimestamp()
                })
            } catch(error) {
                setError('there was an error adding a resume from a job description ' + error)
            }
        }
    }
// function to add parsed job desc resume to server
    const handleAddParsedFromJobDescriptionResume = async () => {
        if(currentUser && !loading && fromJobDesc) {
            try {
                await setDoc(doc(db, 'fromJobDescResume', `${currentUser.uid}`), {
                    firstName: resumeData.firstName,
                    lastName: resumeData.lastName,
                    jobTitle: resumeData.jobTitle,
                    summary: resumeData.summary,
                    experience: resumeData.experience,
                    education: resumeData.education,
                    skills: resumeData.skills,
                    contactInfo: resumeData.contactInfo,
                    companyName: companyName,
                    timestamp: serverTimestamp()
                })
            } catch(error) {
                setError('there was an error adding a resume from a job description' + error)
            }
        }
    };

// this version is to create multiple for premium users so they can bring the data back into a table
    const handleAddFromJobDescriptionPremiumResume = async () => {
        if(currentUser && !loading) {
            try {
                const userDocRef = doc(db, 'defaultFromJobDescResume', `${currentUser.uid}`);
                const userDocSnapshot = await getDoc(userDocRef);
                const userData = userDocSnapshot.data();

                const nextIndex = (userData.premiumResumeVersions || []).length;
                const versionDocRef = doc(db, 'defaultFromJobDescResume', `${currentUser.uid}`, nextIndex.toString())
                await setDoc(versionDocRef, {
                    data: fromJobDesc,
                    companyName: companyName,
                    timestamp: serverTimestamp(),
                })
            } catch(error) {
                setError('there was an error adding a premium resume from a job description ' + error + 'if the issue persists please reach out to Support@Resumancy.com')
            }
        }
    };
// fetch for finding all resumes for premium accounts
    useEffect(() => {
        if (defaultSubscription && currentUser && !loading && (defaultSubscription.name === "Premium (Annual)" || defaultSubscription.name === "Premium")) {
            const fetchPremiumFromJobDescriptionList = async () => {
                const resumeCollectionRef = collection(db, 'defaultFromJobDescResume', `${currentUser.uid}`, 'premiumResumeVersions');
                const resumeQuery = query(resumeCollectionRef);
                const snapshot = await getDocs(resumeQuery);
                const data = [];
                snapshot.forEach((doc) => {
                    data.push(doc.data);
                })
                setListResumeData(data)
            };
            fetchPremiumFromJobDescriptionList();
        }
    }, [defaultSubscription, currentUser])


// make fetch for all fromJobsDesc resumes to determine which version is available
// set state variables for which database is available

// this is going to pose an issue when a new record is created because...
// the corrected version from a previous resume may still be available.
// may need to check for a particular record.
const fetchFromJobDescDataHierarchy = async () => {

}


// make fetch for all data base resumes and determine what is available
// set state variables for which datasets are available
const fetchBaseDataHierarchy = async () => {
    if (currentUser && !loading) {
        try {
            const docRefCorrected = doc(db, 'correctedBaseResume', `${currentUser.uid}`);
            const docRefDefaultScratch = doc(db, 'defaultScratchResume', `${currentUser.uid}`);
            const [fetchedDocCorrected, fetchedDocDefaultScratch] = await Promise.all([
            getDoc(docRefCorrected),
            getDoc(docRefDefaultScratch),
            ]);
            const resumeDataCorrected = fetchedDocCorrected.data();
            const titleData = fetchedDocDefaultScratch.get('title');
            const scratchResumeData = fetchedDocDefaultScratch.get('data');
            if (resumeDataCorrected) {
            setResumeData({
                firstName: resumeDataCorrected.firstName,
                lastName: resumeDataCorrected.lastName,
                jobTitle: resumeDataCorrected.jobTitle,
                summary: resumeDataCorrected.summary,
                contactInfo: resumeDataCorrected.contactInfo,
                experience: resumeDataCorrected.experience,
                education: resumeDataCorrected.education,
                skills: resumeDataCorrected.skills,
                version: 'correctedBase',
            });
            setCorrected(resumeDataCorrected);
            return;
            }
            if (scratchResumeData) {
            setFetchedScratchDefaultTitle(titleData);
            setFetchedScratchDefaultResume(scratchResumeData);
            const parsedSections = breakResumeIntoSections(scratchResumeData.scratchResumeSummary);
            setSections(parsedSections);
            return;
            }
        } catch (error) {
            setError('failed to fetch resume data' + error);
        }
        }
        await fetchResume();
    };

//pulls down parsed resume
    const fetchResume = async () => {
        if(currentUser && !loading) {
            try {
                const docRef = doc(db, 'userResume', `${currentUser.uid}`);
                const fetchedDoc = await getDoc(docRef);
                const resumeData = fetchedDoc.data()
                if(
                    resumeData.firstName &&
                    resumeData.lastName &&
                    resumeData.jobTitle &&
                    resumeData.experience &&
                    resumeData.education &&
                    resumeData.skills &&
                    resumeData.contactInfo
                    ) {
                    setDefaultResume(resumeData)
                    setBase(resumeData)
                }
                if(!resumeData) {
                    setResumeData(defaultResume)
                }
                setFetchedResumeData(resumeData)
            } catch (err) {
                console.log(err)
            }
        }
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false)
        })
        return unsubscribe
    }, []);

    const value = {
        currentUser,
        handleAdd,
        handleAddResume,
        setDefaultResume,
        defaultResume,
        profilePic,
        defaultUserPic,
        profilePicBkg,
        profilePicBkgClr,
        fetchedResumeData,
        error,
        setError,
        edit,
        setEdit,
        listResumeData,
        breakResumeIntoSections,
        setDefaultSubscription,
        setDefaultUserPic,
        setProfilePicBkgClr,
        setUserPic,
        avatarbkgClr,

        defaultSubscription,

        login,
        signup,
        logout,

        resetPassword,

        loading,
        setLoading,
        validationError,
        setValidationError,
        history,
        fetchedScratchDefaultResume,
        fetchedScratchDefaultTitle,
        sections,
        resumeData, 
        setResumeData,
        currentResumeStyle, 
        setCurrentResumeStyle,
        setCorrectedFromJobDesc,

        userFailsafe, 
        setUserFailsafe,

        handleAddCorrectedBaseResume,
        handleAddCorrectedFromJobDescResume,
        correctedBaseResumeData,
        setCorrectedBaseResumeData,

        setCoverLetterContent,

        resumeContent,
        setResumeContent,

        companyName,
        setCompanyName,
        base,
        setBase,
        corrected,
        setCorrected,
        fromJobDesc,
        setFromJobDesc,
        coverLetterContent,
        setCoverLetterContent,

        fetchFromJobDescResume,

        handleAddFromJobDescriptionResume,
        handleAddFromJobDescriptionPremiumResume,

        fetchDefaultFromJobDescResume,
        fetchedDefaultFromJobsDescResume,

        fetchBaseDataHierarchy
    }
    
return (
    <AuthContext.Provider value={value}>
        {loading ? <LoadingPage/> : children}
    </AuthContext.Provider>
)
}