import React, { createContext, useContext, useState, useEffect } from 'react';

const AdsLogicContext = createContext();

export function useAdsLogicContext() {
    return useContext(AdsLogicContext);
}

export function AdsBuilderProvider({ children }) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [timer, setTimer] = useState(null);
    const toggleModal = () => {
        setIsModalVisible(!isModalVisible);
        setTimer(25); 
    };
    
    useEffect(() => {
        const interval = setInterval(() => {
            if (timer > 0) {
                setTimer(timer - 1);
            }
        }, 1000);
        if (timer === 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [timer]);

    const value = {
        isModalVisible,
        toggleModal,
        timer,
    }
    return (
        <AdsLogicContext.Provider value={ value }>
            {children}
        </AdsLogicContext.Provider>
    );
}
